<template>
    <div>

        <vxe-grid size="mini" ref="xGrid" round auto-resize align="center" :data="grid.data" :columns="grid.column">
            <template #operation="{ row }">
                <!-- {{row}} -->
                <vxe-button content="修改" status="warning" @click="groupFormOpen(row)"></vxe-button>
                <vxe-button content="删除" status="danger" @click="gridRemove(row)"></vxe-button>
            </template>
        </vxe-grid>

    </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, nextTick, onMounted } from 'vue';
import { VXETable, VxeFormEvents, VxeFormInstance, VxeGridInstance, VxeToolbarInstance } from 'vxe-table'
import request from '@/plugin/request';
export default defineComponent({
    components: {

    },
    setup () {

        const grid = reactive({
            column: [
                // { type: 'checkbox', width: 100 },
                { type: 'seq', width: 50 },
                { field: 'name', title: '名称', width: 120 },
                { field: 'stock', title: '库存' },
            ],
            data: []
        })


        const handleQuery = async () => {

            request('/customer/weimob/coupon', {}, { method: 'GET' }).then(response => {

                const { result } = response
                grid.data = result.data
                console.log(result)

            })
        }


        onMounted(() => {


            handleQuery()


        })

        return {
            grid,

        };

    }
});
</script>


<style scoped>
</style>