<template>
    <div>
        <vxe-grid v-bind="gridOptions" ref="xGrid">
            <template #avatar="{ row }">

                <el-avatar shape="square" :src="row.headUrl"></el-avatar>

            </template>

            <template #pager>
                <vxe-pager :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']" v-model:current-page="tablePage.currentPage" v-model:page-size="tablePage.pageSize" :total="tablePage.total" @page-change="handlePageChange">
                </vxe-pager>
            </template>
        </vxe-grid>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, nextTick, onMounted } from 'vue';
import { VXETable, VxeFormEvents, VxeFormInstance, VxeGridInstance, VxeToolbarInstance } from 'vxe-table'
import { VxeGridProps, VxePagerEvents } from 'vxe-table'
import request from '@/plugin/request';
export default defineComponent({
    components: {

    },
    setup () {

        const tablePage = reactive({
            total: 0,
            currentPage: 1,
            pageSize: 100
        })

        const gridOptions = reactive<VxeGridProps>({
            height: 500,
            size: 'mini',
            round: true,
            autoResize: true,
            align: 'center',
            loading: false,
            data: [],
            columns: [
                { type: 'seq', width: 50 },
                { field: 'CODE', title: '编号', minWidth: 50 },
                { field: 'NAME', title: '名称', minWidth: 50 },
                { field: 'DISCOUNT', title: '折扣', minWidth: 50 },
                { field: 'DESCRIPTION', title: '备注', minWidth: 50 }
            ]
        })

        const searchEvent = () => {
            tablePage.currentPage = 1

        }

        const handlePageChange: VxePagerEvents.PageChange = ({ currentPage, pageSize }) => {
            tablePage.currentPage = currentPage
            tablePage.pageSize = pageSize

            handleQuery()

        }

        const handleQuery = async () => {

            request('/customer/burgeon/rank', { currentPage: tablePage.currentPage, pageSize: tablePage.pageSize }, { method: 'GET' }).then(response => {

                const { result } = response
                gridOptions.data = result.data
                tablePage.total = result.total
                console.log(result)

                // grid.data = result

            })
        }

        onMounted(() => {


            handleQuery()


        })

        return {
            tablePage,
            gridOptions,
            searchEvent,
            handlePageChange
        }

    }
});
</script>


<style scoped>
</style>