<template>
    <div>

        <vxe-form :data="searchForm.data" @submit="searchSubmit" @reset="searchReset" size="medium" ref="xSearchForm">

            <vxe-form-item title="手机" field="mobile" :item-render="{}" span="6">
                <template #default="{ data }">
                    <vxe-input v-model="data.mobile" placeholder="支持模糊查询" clearable></vxe-input>
                </template>
            </vxe-form-item>

            <vxe-form-item title="平台" field="platform" :item-render="{}" span="6">
                <template #default="{ data }">
                    <vxe-select v-model="data.platform" placeholder="默认显示全部会员" multiple clearable>
                        <vxe-option value="weimob" label="微盟"></vxe-option>
                        <vxe-option value="burgeon" label="伯俊"></vxe-option>
                    </vxe-select>
                </template>
            </vxe-form-item>

            <vxe-form-item title="检测" field="check" :item-render="{}" span="6">
                <template #default="{ data }">
                    <vxe-select v-model="data.check" placeholder="默认显示全部会员" clearable>
                        <vxe-option value="weimob" label="没有伯俊只有微盟"></vxe-option>
                        <vxe-option value="weimobFull" label="没有伯俊微盟会员资料完整"></vxe-option>
                    </vxe-select>
                </template>
            </vxe-form-item>

            <vxe-form-item align="left" span="24">
                <template #default>
                    <vxe-button type="submit" status="primary" content="查询"></vxe-button>
                    <vxe-button type="reset" content="重置"></vxe-button>
                    <vxe-button status="success" content="合并" @click="mergeHandleModal"></vxe-button>
                </template>
            </vxe-form-item>

        </vxe-form>

        <vxe-grid v-bind="gridOptions" ref="xGrid">
            <template #avatar="{ row }">

                <el-avatar shape="square" :src="row.avatar"></el-avatar>

            </template>

            <template #platform="{ row }">

                <el-space wrap>
                    <el-tag effect="dark" size="small" v-if="row.burgeon_id">伯俊</el-tag>
                    <el-tag effect="dark" size="small" type="success" v-if="row.weimob_id">微盟</el-tag>
                </el-space>

            </template>

            <template #operation="{ row }">
                <vxe-button status="primary" content="读取微盟" size="mini" @click="handleLoadWeimob(row)" v-if="row.weimob_id"></vxe-button>
                <vxe-button status="primary" content="写入伯俊" size="mini" @click="handleInsertBurgeon(row)" v-if="!row.burgeon_id && row.name"></vxe-button>
            </template>

            <template #pager>
                <vxe-pager :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']" v-model:current-page="tablePage.currentPage" v-model:page-size="tablePage.pageSize" :total="tablePage.total" @page-change="handlePageChange">
                </vxe-pager>
            </template>
        </vxe-grid>

        <vxe-modal v-model="mergeModal.visible" title="等级测试" width="40%" height="50%">
            <template #default>

                <vxe-form :data="mergeModal.form.data" size="medium" :rules="mergeModal.form.rules" ref="mergeModalForm">

                    <vxe-form-item title="手机" field="mobile" :item-render="{}" span="24">
                        <template #default="{ data }">
                            <vxe-input v-model="data.mobile" placeholder="填写要合并会员的手机号" clearable></vxe-input>
                        </template>
                    </vxe-form-item>

                    <vxe-form-item align="center" span="24">
                        <template #default>
                            <vxe-button status="primary" size="mini" content="合并" @click="mergeHandleModalFormSubmit"></vxe-button>
                        </template>
                    </vxe-form-item>

                    <vxe-form-item title="结果" field="result" :item-render="{}" span="24">
                        <template #default="{ data }">
                            <vxe-textarea v-model="data.result" placeholder="点击合并后返回结果在这里显示" readonly :autosize="{ minRows: 10 }"></vxe-textarea>
                        </template>
                    </vxe-form-item>

                </vxe-form>

            </template>
        </vxe-modal>

    </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, nextTick, onMounted } from 'vue';
import { VXETable, VxeFormEvents, VxeFormInstance, VxeGridInstance, VxeToolbarInstance } from 'vxe-table'
import { VxeGridProps, VxePagerEvents } from 'vxe-table'
import request from '@/plugin/request';
import { getTableScroll } from '@/plugin/tools'
export default defineComponent({
    components: {

    },
    setup () {

        const xSearchForm = ref({} as VxeFormInstance)

        const tablePage = reactive({
            total: 0,
            currentPage: 1,
            pageSize: 100
        })

        const gridOptions = reactive<VxeGridProps>({
            height: 100,
            size: 'mini',
            round: true,
            autoResize: true,
            align: 'center',
            loading: false,
            data: [],
            columns: [
                { type: 'seq', width: 50 },
                { title: '头像', slots: { default: 'avatar' }, width: 100 },
                { field: 'mobile', title: '手机', minWidth: 100 },
                { field: 'name', title: '姓名', minWidth: 100 },
                { field: 'sex', title: '性别', minWidth: 100 },
                { field: 'birthday', title: '生日', minWidth: 100 },
                { title: '平台', slots: { default: 'platform' }, minWidth: 200 },
                // { field: 'burgeon_id', title: '伯俊ID', minWidth: 50 },
                { field: 'burgeon_rank', title: '伯俊等级', minWidth: 100 },
                // { field: 'weimob_id', title: '微盟ID', minWidth: 50 },
                { field: 'weimob_rank', title: '微盟等级', minWidth: 100 },
                { field: 'update_time', title: '更新时间', minWidth: 150 },
                { title: '操作', slots: { default: 'operation' }, minWidth: 200 },

            ]
        })


        const handlePageChange: VxePagerEvents.PageChange = ({ currentPage, pageSize }) => {
            tablePage.currentPage = currentPage
            tablePage.pageSize = pageSize

            handleQuery()

        }

        const handleQuery = async () => {

            request('/customer/customer/list', { currentPage: tablePage.currentPage, pageSize: tablePage.pageSize, ...searchForm.data }, { method: 'GET' }).then(response => {
                const { result } = response
                gridOptions.data = result.data
                tablePage.total = result.total

            })
        }

        onMounted(() => {

            handleQuery()

        })


        nextTick(() => {
            gridOptions.height = getTableScroll() - 96

        })

        const handleLoadWeimob = async (row: any) => {

            request('/customer/customer/list/weimob/load', row, { method: 'POST', message: true }).then(() => handleQuery())

        }

        const handleInsertBurgeon = async (row: any) => {

            request('/customer/customer/list/burgeon/insert', row, { method: 'POST', message: true }).then(() => handleQuery())

        }

        const searchForm = reactive({
            data: {
                mobile: undefined,
                platform: undefined,
                check: undefined
            },
            rules: {}
        })

        const searchSubmit: VxeFormEvents.Submit = () => {
            const $form = xSearchForm.value
            tablePage.currentPage = 1
            handleQuery()
        }

        const searchReset: VxeFormEvents.Reset = () => {
            handleQuery()
        }

        // 合并功能

        const mergeModalForm = ref({} as VxeFormInstance)


        const mergeModal = reactive({
            visible: false,
            form: {
                data: {
                    mobile: undefined,
                    result: undefined
                },
                rules: {
                    mobile: [
                        { required: true, message: '必填项' },
                        { pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '格式不正确', trigger: 'change' }
                    ],
                }
            }
        })


        const mergeHandleModal = () => {
            mergeModal.form.data.mobile = undefined

            mergeModal.visible = true
        }

        const mergeHandleModalFormSubmit = () => {
            const $form = mergeModalForm.value
            $form.validate().then(() => {
                request('/customer/customer/list/merge', mergeModal.form.data, { method: 'POST', message: true }).then(response => {

                    const { result } = response

                    mergeModal.form.data.result = result

                })
            })
        }
        
        return {
            tablePage,
            gridOptions,
            handlePageChange,
            handleLoadWeimob,
            handleInsertBurgeon,
            searchForm,
            searchSubmit,
            searchReset,
            xSearchForm,
            mergeModalForm,
            mergeModal,
            mergeHandleModal,
            mergeHandleModalFormSubmit
        }

    }
});
</script>


<style scoped>
</style>