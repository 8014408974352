
import { defineComponent, ref, reactive, nextTick, onMounted } from 'vue';
import { VXETable, VxeFormEvents, VxeFormInstance, VxeGridInstance, VxeToolbarInstance } from 'vxe-table'
import request from '@/plugin/request';
export default defineComponent({
    components: {

    },
    setup () {

        const grid = reactive({
            column: [
                // { type: 'checkbox', width: 100 },
                { type: 'seq', width: 50 },
                { field: 'name', title: '名称', width: 120 },
                { field: 'stock', title: '库存' },
            ],
            data: []
        })


        const handleQuery = async () => {

            request('/customer/weimob/coupon', {}, { method: 'GET' }).then(response => {

                const { result } = response
                grid.data = result.data
                console.log(result)

            })
        }


        onMounted(() => {


            handleQuery()


        })

        return {
            grid,

        };

    }
});
