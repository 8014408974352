
import { defineComponent, ref, reactive, nextTick, onMounted } from 'vue';
import { VXETable, VxeFormEvents, VxeFormInstance, VxeGridInstance, VxeToolbarInstance } from 'vxe-table'
import { VxeGridProps, VxePagerEvents } from 'vxe-table'
import request from '@/plugin/request';
import { getTableScroll } from '@/plugin/tools'
export default defineComponent({
    components: {

    },
    setup () {

        const xSearchForm = ref({} as VxeFormInstance)

        const tablePage = reactive({
            total: 0,
            currentPage: 1,
            pageSize: 100
        })

        const gridOptions = reactive<VxeGridProps>({
            height: 100,
            size: 'mini',
            round: true,
            autoResize: true,
            align: 'center',
            loading: false,
            data: [],
            columns: [
                { type: 'seq', width: 50 },
                { title: '头像', slots: { default: 'avatar' }, width: 100 },
                { field: 'mobile', title: '手机', minWidth: 100 },
                { field: 'name', title: '姓名', minWidth: 100 },
                { field: 'sex', title: '性别', minWidth: 100 },
                { field: 'birthday', title: '生日', minWidth: 100 },
                { title: '平台', slots: { default: 'platform' }, minWidth: 200 },
                // { field: 'burgeon_id', title: '伯俊ID', minWidth: 50 },
                { field: 'burgeon_rank', title: '伯俊等级', minWidth: 100 },
                // { field: 'weimob_id', title: '微盟ID', minWidth: 50 },
                { field: 'weimob_rank', title: '微盟等级', minWidth: 100 },
                { field: 'update_time', title: '更新时间', minWidth: 150 },
                { title: '操作', slots: { default: 'operation' }, minWidth: 200 },

            ]
        })


        const handlePageChange: VxePagerEvents.PageChange = ({ currentPage, pageSize }) => {
            tablePage.currentPage = currentPage
            tablePage.pageSize = pageSize

            handleQuery()

        }

        const handleQuery = async () => {

            request('/customer/customer/list', { currentPage: tablePage.currentPage, pageSize: tablePage.pageSize, ...searchForm.data }, { method: 'GET' }).then(response => {
                const { result } = response
                gridOptions.data = result.data
                tablePage.total = result.total

            })
        }

        onMounted(() => {

            handleQuery()

        })


        nextTick(() => {
            gridOptions.height = getTableScroll() - 96

        })

        const handleLoadWeimob = async (row: any) => {

            request('/customer/customer/list/weimob/load', row, { method: 'POST', message: true }).then(() => handleQuery())

        }

        const handleInsertBurgeon = async (row: any) => {

            request('/customer/customer/list/burgeon/insert', row, { method: 'POST', message: true }).then(() => handleQuery())

        }

        const searchForm = reactive({
            data: {
                mobile: undefined,
                platform: undefined,
                check: undefined
            },
            rules: {}
        })

        const searchSubmit: VxeFormEvents.Submit = () => {
            const $form = xSearchForm.value
            tablePage.currentPage = 1
            handleQuery()
        }

        const searchReset: VxeFormEvents.Reset = () => {
            handleQuery()
        }

        // 合并功能

        const mergeModalForm = ref({} as VxeFormInstance)


        const mergeModal = reactive({
            visible: false,
            form: {
                data: {
                    mobile: undefined,
                    result: undefined
                },
                rules: {
                    mobile: [
                        { required: true, message: '必填项' },
                        { pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '格式不正确', trigger: 'change' }
                    ],
                }
            }
        })


        const mergeHandleModal = () => {
            mergeModal.form.data.mobile = undefined

            mergeModal.visible = true
        }

        const mergeHandleModalFormSubmit = () => {
            const $form = mergeModalForm.value
            $form.validate().then(() => {
                request('/customer/customer/list/merge', mergeModal.form.data, { method: 'POST', message: true }).then(response => {

                    const { result } = response

                    mergeModal.form.data.result = result

                })
            })
        }
        
        return {
            tablePage,
            gridOptions,
            handlePageChange,
            handleLoadWeimob,
            handleInsertBurgeon,
            searchForm,
            searchSubmit,
            searchReset,
            xSearchForm,
            mergeModalForm,
            mergeModal,
            mergeHandleModal,
            mergeHandleModalFormSubmit
        }

    }
});
