
import { defineComponent, ref, reactive, nextTick, onMounted } from 'vue';
import { VXETable, VxeFormEvents, VxeFormInstance, VxeGridInstance, VxeGridProps, VxeToolbarInstance } from 'vxe-table'
import linq from 'linq'
import dayjs from 'dayjs'
import request from '@/plugin/request';
export default defineComponent({
    components: {

    },
    setup () {
        const xGrid = ref({} as VxeGridInstance)
        const couponGrid = ref({} as VxeGridInstance)

        const gridOptions = reactive<VxeGridProps>({
            stripe: true,
            size: 'mini',
            round: true,
            autoResize: true,
            align: 'center',
            loading: false,
            toolbarConfig: {
                slots: {
                    buttons: 'toolbar_buttons'
                }
            },
            columns: [
                { type: 'seq', width: 50, title: '序号' },
                { field: 'name', title: '名称', minWidth: 120, editRender: { name: 'input', attrs: { placeholder: '请输入名称' } } },
                { field: 'burgeon_rank', title: '伯俊等级', minWidth: 120, editRender: { name: '$select', options: [] } },
                { field: 'weimob_rank', title: '微盟等级', minWidth: 120, editRender: { name: '$select', options: [] } },
                { field: 'lower_limit', title: '充值下限', minWidth: 120, editRender: { name: 'input', attrs: { type: 'number' } } },
                { field: 'upper_limit', title: '充值上限', minWidth: 120, editRender: { name: 'input', attrs: { type: 'number' } } },
                { field: 'limit', title: '有效期（天）', minWidth: 120, editRender: { name: 'input', attrs: { type: 'number' } } },
                { field: 'operation', title: '操作', slots: { default: 'operation' }, width: 200 },
            ],
            editRules: {
                name: [
                    { required: true, message: '必填项' }
                ],
                burgeon_rank: [
                    { required: true, message: '必填项' }
                ],
                weimob_rank: [
                    { required: true, message: '必填项' }
                ],
                lower_limit: [
                    { required: true, message: '必填项' }
                ],
                upper_limit: [
                    { required: true, message: '必填项' }
                ],
                limit: [
                    { required: true, message: '必填项' }
                ],
            },
            data: []
        })

        const insertEvent = () => {
            const $grid = xGrid.value
            $grid.insert({
                name: undefined,
                burgeon_rank: undefined,
                weimob_rank: undefined,
                lower_limit: undefined,
                upper_limit: undefined,
                limit: undefined,
            })

        }

        const saveEvent = () => {

            const $grid = xGrid.value
            if ($grid) {

                $grid.fullValidate().then(() => {

                    let { insertRecords, removeRecords, updateRecords } = $grid.getRecordset()

                    if (insertRecords.length > 0) {
                        request('/customer/customer/rank', insertRecords, { method: 'POST', loading: true, message: true })
                            .then(() => { handleQuery() })
                    }

                    if (updateRecords.length > 0) {
                        request('/customer/customer/rank', updateRecords, { method: 'POST', loading: true, message: true })
                            .then(() => { handleQuery() })
                    }

                })

            }
        }

        const handleQuery = () => {

            request('/customer/customer/rank', {}, { method: 'GET', loading: true }).then(response => {

                const { result } = response

                gridOptions.data = result.data
            })


        }

        const couponModal = reactive({
            visible: false,
            rank_id: '',
            couponSelectd: '',
            couponList: []
        })

        const couponModalOpen = (row: any) => {
            if (!row.id) { VXETable.modal.message({ content: '请先保存等级后再维护券包', status: 'error' }); return }

            couponModal.rank_id = row.id
            couponModal.couponSelectd = ''

            request('/customer/weimob/coupon', {}, { method: 'GET' }).then(response => {
                const { result } = response
                couponModal.couponList = result.data.filter((r: any) => r.status === 5)
            })

            request('/customer/customer/rank/coupon', { rank_id: row.id }, { method: 'GET' }).then(response => {
                const { result } = response

                if (result.data) {
                    const $grid = couponGrid.value

                    if ($grid) {
                        $grid.loadData(result.data)
                    }
                }

            })

            couponModal.visible = true

        }

        const couponModalSave = () => {

            const $grid = couponGrid.value
            if ($grid) {

                $grid.fullValidate().then(() => {

                    let { insertRecords, removeRecords, updateRecords } = $grid.getRecordset()

                    if (insertRecords.length > 0) {
                        request('/customer/customer/rank/coupon', insertRecords, { method: 'POST', loading: true, message: true })
                            .then(() => { handleQuery() })
                    }

                    if (updateRecords.length > 0) {
                        request('/customer/customer/rank/coupon', updateRecords, { method: 'POST', loading: true, message: true })
                            .then(() => { handleQuery() })
                    }

                })

            }


        }

        const couponGridOptions = reactive<VxeGridProps>({
            stripe: true,
            size: 'mini',
            round: true,
            autoResize: true,
            align: 'center',
            loading: false,
            toolbarConfig: {
                slots: {
                    buttons: 'toolbar_buttons'
                }
            },
            columns: [
                { type: 'seq', width: 50, title: '序号' },
                { field: 'card_template_id', title: '编码', minWidth: 120 },
                { field: 'card_template_name', title: '名称', minWidth: 120 },
                { field: 'amount', title: '数量', minWidth: 120, editRender: { name: 'input', attrs: { type: 'number' } } },
                {
                    field: 'status', title: '状态', minWidth: 120,
                    formatter ({ cellValue }) {
                        let result = ''
                        switch (cellValue) {
                            case 1: result = '已删除'; break;
                            case 2: result = '审核通过'; break;
                            case 3: result = '审核中'; break;
                            case 4: result = '审核失败'; break;
                            case 5: result = '未过期'; break;
                            case 6: result = '已过期'; break;
                        }

                        return result
                    }
                },
                { field: 'operation', title: '操作', slots: { default: 'operation' }, width: 200 },
            ],
            editRules: {
                amount: [
                    { type: 'number', min: 1, message: '发券数必须大于0' }
                ]
            },
            data: []
        })

        const couponSelectd = (params: any) => {

            const $grid = couponGrid.value

            if ($grid.getData().findIndex(r => r.card_template_id === params) == -1) {
                $grid.insert({
                    rank_id: couponModal.rank_id,
                    card_template_id: params,
                    card_template_name: linq.from(couponModal.couponList).where((f: any) => f.id == params).select((f: any) => f.name).firstOrDefault(),
                    amount: 0,
                    temp: true
                })
            }


        }

        const couponGridRemove = (row: any) => {

            const $grid = couponGrid.value
            if ($grid) {
                if (row.temp) {
                    $grid.remove(row)
                } else {
                    request('/customer/customer/rank/coupon', row, { method: 'DELETE', loading: true, message: true })
                        .then(() => { $grid.remove(row) })
                }

            }

        }

        onMounted(() => {

            request('/customer/customer/rank/status', {}, { method: 'GET' }).then(response => {

                const { result } = response

                if (result) { active.value = result.status.active }

            })


            request('/customer/customer/rank/dict', {}, { method: 'GET' }).then(response => {

                const { result } = response

                if (result.data) {

                    const $grid = xGrid.value

                    if ($grid) {
                        const burgeon = $grid.getColumnByField('burgeon_rank')
                        if (burgeon && burgeon.editRender) {
                            burgeon.editRender.options = result.data.burgeon
                        }
                        const weimob = $grid.getColumnByField('weimob_rank')
                        if (weimob && weimob.editRender) {
                            weimob.editRender.options = result.data.weimob
                        }
                    }
                }

            })


            handleQuery()

        })


        const testModalForm = ref({} as VxeFormInstance)


        const testModal = reactive({
            visible: false,
            form: {
                data: {
                    rank_id: undefined,
                    mobile: undefined,
                    result: undefined
                },
                rules: {
                    rank_id: [
                        { required: true, message: '必填项' },

                    ],
                    mobile: [
                        { required: true, message: '必填项' },
                        { pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '格式不正确', trigger: 'change' }
                    ],
                }
            }
        })


        const handleTestModal = () => {
            testModal.form.data.rank_id = undefined
            testModal.form.data.mobile = undefined

            testModal.visible = true
        }

        const handleTestModalFormSubmit = () => {

            testModal.form.data.result = undefined
            const $form = testModalForm.value
            $form.validate().then(() => {
                request('/customer/customer/rank/test', testModal.form.data, { method: 'POST', message: true }).then(response => {

                    const { result } = response

                    testModal.form.data.result = result

                })
            })
        }


        const queueModalForm = ref({} as VxeFormInstance)

        const queueModal = reactive({
            visible: false,
            form: {
                data: {
                    rank_id: undefined,
                    rank_name: undefined,
                    date: undefined,
                    card_template_id: undefined,
                    active: false,
                    stock: 0,
                    cycle: 0,
                    result: ''
                },
                rules: {
                    rank_id: [
                        { required: true, message: '必填项' },
                    ],
                    date: [
                        { required: true, message: '必填项' },
                    ],
                    card_template_id: [
                        { required: true, message: '必填项' },
                    ],
                    cycle: [
                        { required: true, message: '必填项' },
                        { type: 'number', min: 1, message: '周期必须大于0' }
                    ],
                    active: [
                        { required: true, message: '必填项' },
                    ],
                },
                couponList: []
            }
        })

        const handleQueueModal = (row: any) => {
            if (!row.id) { VXETable.modal.message({ content: '请先保存等级后再维护队列', status: 'error' }); return }
            queueModal.form.data.rank_id = row.id
            queueModal.form.data.rank_name = row.name

            request('/customer/customer/rank/queue', { rank_id: row.id }, { method: 'GET' }).then(response => {

                if (response.result.data) {
                    queueModal.form.data.date = response.result.data.date
                    queueModal.form.data.card_template_id = response.result.data.card_template_id
                    queueModal.form.data.cycle = response.result.data.cycle
                    queueModal.form.data.active = response.result.data.active
                    queueModal.form.data.stock = response.result.data.stock

                    queueModal.form.data.result = ''

                    for (let i = 0;i < queueModal.form.data.cycle;i++) {
                        queueModal.form.data.result += `第${i + 1}期 发券日期：${dayjs(queueModal.form.data.date).add(i, 'month').format('YYYY-MM-DD')}`
                        queueModal.form.data.result += '\n'
                    }
                }

                request('/customer/customer/coupon/weimob/coupon', {}, { method: 'GET' }).then(response => {
                    queueModal.form.couponList = response.result.data
                    queueModal.visible = true
                })

            })




        }

        const handleCoupon = (params: any) => {
            queueModal.form.data.stock = linq.from(queueModal.form.couponList).where((f: any) => f.id === params.value).select((f: any) => f.stock).firstOrDefault()
        }


        const handleQueueModalFormSubmit = () => {
            const $form = queueModalForm.value
            $form.validate().then(() => {
                request('/customer/customer/rank/queue', queueModal.form.data, { method: 'POST', message: true }).then(response => {


                })
            })
        }


        const handleCalcQueue = (params: any) => {

            const { data } = queueModal.form

            if (data.date && data.cycle != 0) {

                data.result = ''

                for (let i = 0;i < data.cycle;i++) {



                    data.result += `第${i + 1}期 发券日期：${dayjs(data.date).add(i, 'month').format('YYYY-MM-DD')}`
                    data.result += '\n'


                }



            }


        }


        const active = ref(0)
        const activeChange = (params: any) => {
            request('/customer/customer/rank/status', { value: params.value }, { method: 'POST' }).then(response => { })
        }


        return {
            xGrid,
            gridOptions,
            insertEvent,
            saveEvent,
            couponModal,
            couponModalOpen,
            couponModalSave,
            couponGrid,
            couponGridOptions,
            couponSelectd,
            couponGridRemove,
            testModal,
            handleTestModal,
            testModalForm,
            handleTestModalFormSubmit,
            queueModalForm,
            queueModal,
            handleQueueModal,
            handleCoupon,
            handleQueueModalFormSubmit,
            handleCalcQueue,
            active,
            activeChange
        }
    }
});
