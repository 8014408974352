
import { defineComponent, ref, reactive, nextTick, onMounted } from 'vue';
import { VXETable, VxeFormEvents, VxeFormInstance, VxeGridInstance, VxeToolbarInstance } from 'vxe-table'
import { VxeGridProps, VxePagerEvents } from 'vxe-table'
import request from '@/plugin/request';
import { getTableScroll } from '@/plugin/tools'
export default defineComponent({
    components: {

    },
    setup () {

        const tablePage = reactive({
            total: 0,
            currentPage: 1,
            pageSize: 100
        })

        const gridOptions = reactive<VxeGridProps>({
            height: 500,
            size: 'mini',
            round: true,
            autoResize: true,
            align: 'center',
            loading: false,
            data: [],
            columns: [
                { type: 'seq', width: 50 },
                { field: 'avatar', title: '头像', slots: { default: 'avatar' }, width: 200 },
                { field: 'wid', title: 'wid', minWidth: 50 },
                { field: 'nickname', title: '昵称', minWidth: 50 },
                { field: 'name', title: '姓名', minWidth: 50 },
                { field: 'mobile', title: '手机', minWidth: 50 },
            ]
        })

        const searchEvent = () => {
            tablePage.currentPage = 1

        }

        const handlePageChange: VxePagerEvents.PageChange = ({ currentPage, pageSize }) => {
            tablePage.currentPage = currentPage
            tablePage.pageSize = pageSize

            handleQuery()

        }

        const handleQuery = async () => {

            request('/customer/weimob/customer', { currentPage: tablePage.currentPage, pageSize: tablePage.pageSize }, { method: 'GET' }).then(response => {

                const { result } = response
                gridOptions.data = result.data
                tablePage.total = result.total
                console.log(result)

                // grid.data = result

            })
        }

        nextTick(() => {
            gridOptions.height = getTableScroll()

        })

        onMounted(() => {


            handleQuery()


        })

        return {
            tablePage,
            gridOptions,
            searchEvent,
            handlePageChange
        }

    }
});
