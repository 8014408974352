<template>
    <div>
        <vxe-form :data="searchForm.data" size="mini" ref="xSearchForm">

            <vxe-form-item title="手机" field="mobile" :item-render="{}" span="6">
                <template #default="{ data }">
                    <vxe-input v-model="data.mobile" placeholder="请输入手机号" clearable></vxe-input>
                </template>
            </vxe-form-item>

            <vxe-form-item title="时间" field="date" :item-render="{}" span="6">
                <template #default="{ data }">
                    <vxe-input v-model="data.date" placeholder="请选择" type="date"></vxe-input>
                </template>
            </vxe-form-item>

            <vxe-form-item align="left" span="24">
                <template #default>
                    <vxe-button type="submit" status="primary" content="查询" @click="queueGridMethod.handleQuery"></vxe-button>
                    <vxe-button type="reset" content="重置"></vxe-button>

                </template>
            </vxe-form-item>

        </vxe-form>

        <vxe-grid v-bind="queueGrid" ref="queueGridRef">

            <template #operation="{ row }">
                <vxe-button status="primary" size="mini" @click="queueGridMethod.handleModal(row)">队列共：{{row.queueList.length}}条</vxe-button>
                <vxe-button status="danger" content="删除" size="mini" @click="queueGridMethod.handleRemove(row)"></vxe-button>
            </template>
        </vxe-grid>

        <vxe-modal v-model="mergeModal.visible" title="" width="80%" height="95%">
            <template #default>
                <vxe-grid ref='xGrid' v-bind="modalGrid" id="_id" keep-source>
                </vxe-grid>
            </template>
        </vxe-modal>

    </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, nextTick, onMounted } from 'vue';
import { VXETable, VxeFormEvents, VxeFormInstance, VxeGridInstance, VxeGridProps, VxeToolbarInstance } from 'vxe-table'
import linq from 'linq'
import dayjs from 'dayjs'
import request from '@/plugin/request';
import { getTableScroll } from '@/plugin/tools'

export default defineComponent({
    components: {

    },
    setup () {


        const searchForm = reactive({
            data: {
                mobile: undefined,
                date: undefined,

            },
            rules: {}
        })
        const queueGridRef = ref({} as VxeGridInstance)

        const mergeModal = reactive({

            visible: false,

        })

        const queueGrid = reactive<VxeGridProps>({
            size: 'mini',
            round: true,
            autoResize: true,
            align: 'center',
            loading: false,
            columns: [
                { type: 'seq', width: 50, title: '序号' },
                { field: 'type', title: '类型', minWidth: 120 },
                { field: 'mobile', title: '手机', minWidth: 120 },
                { field: 'time', title: '创建时间', minWidth: 130 },
                { field: 'delay', title: '执行时间', minWidth: 130 },
                { field: 'data', title: '数据', minWidth: 300 },
                { field: 'operation', title: '操作', slots: { default: 'operation' }, width: 200 },
            ],
            data: []

        })

        const modalGrid = reactive<VxeGridProps>({
            size: 'mini',
            round: true,
            autoResize: true,
            align: 'center',
            columns: [
                { type: 'seq', width: 50, title: '序号' },
                { field: 'type', title: '类型', minWidth: 120 },
                { field: 'name', title: '名称', minWidth: 120 },
                { field: 'time', title: '创建时间', minWidth: 130 },
                { field: 'delay', title: '执行时间', minWidth: 130 },
                { field: 'data', title: '数据', minWidth: 300 },
            ],
            data: []

        })


        const queueGridMethod = reactive({
            handleQuery: async () => {
               
                queueGrid.loading = true
                request('/customer/queue', searchForm.data, { method: 'GET' }).then(response => {
                    const { result } = response
                    if (result) { queueGrid.data = result }
                }).finally(() => { queueGrid.loading = false })

            },
            handleModal: async (row: any) => {
                mergeModal.visible = true
                modalGrid.data = row.queueList
              

            },
            handleRemove: async (row: any) => {

                request('/customer/queue', row, { method: 'DELETE' }).then(response => {
                    queueGridMethod.handleQuery()
                })


            }
        })



        onMounted(() => {
            queueGridMethod.handleQuery()
            queueGrid
        })

        nextTick(() => {
            queueGrid.height = getTableScroll() - 100
        })

        return {
            searchForm,
            mergeModal,
            queueGrid,
            queueGridRef,
            queueGridMethod,
            modalGrid
        }
    }
});
</script>


<style scoped>
</style>